module.exports = function( $ ) {
	var $carousel = $( this ).find( '.elementor-image-carousel' );
	if ( ! $carousel.length ) {
		return;
	}

	var respondTo = 'window';

	if (elementorFrontendConfig.isEditMode) {
		respondTo = 'iframe-window';
	}



	var savedOptions = $carousel.data( 'slider_options' ),
		defaultOptions = {
			respondTo: respondTo,
			rtl: parseInt(elementorFrontendConfig.is_rtl) ? true : false,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: savedOptions.slidesToShowTablet,
						slidesToScroll: savedOptions.slidesToShowTablet
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: savedOptions.slidesToShowMobile,
						slidesToScroll: savedOptions.slidesToShowMobile
					}
				}
			]
		},



		slickOptions = $.extend( {}, defaultOptions, savedOptions );

	$carousel.slick( slickOptions );


	if (elementorFrontendConfig.isEditMode) {
		$(window).on('changedDeviceMode', function () {
			$carousel.slick('getSlick').checkResponsive();
		});
	}


};
